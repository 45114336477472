// ./src/utils/growthbook.ts
import type { GrowthBookSSRData } from '@growthbook/growthbook-react';
import {
    GrowthBook,
    useFeatureIsOn as _useFeatureIsOn,
    useGrowthBook as _useGrowthBook,
    useFeatureValue as _useFeatureValue,
} from '@growthbook/growthbook-react';
import { useRootData } from './use-root-data';
import type { Tracker } from '@archipro-website/tracker';
import { useEffect, useState } from 'react';
import { useLatest } from 'ahooks';

const _whitelistProfessional = (
    id: keyof GrowthBookAppFeatures,
    professionalID: number | null,
    whitelist: number[]
) => {
    return professionalID && whitelist.includes(professionalID)
        ? false
        : _useFeatureIsOn<GrowthBookAppFeatures>(id);
};

// Define all our growthbook feature flags and types here, for use in client application
export interface GrowthBookAppFeatures {
    'logged-in-share-information-downloads': boolean;
    'guest-homepage': boolean;
    'product_tagging_dots': boolean;
    'upgraded-enquiry-flow': string;
    'site-gating': boolean;
    'article-gating': boolean;
    'design-board-gating': boolean;
    'directory-tile-slider': boolean;
    'directory-tile-downloads': boolean;
    'project_tile_stats': boolean;
    'directory-potms': boolean;
}

export const useGrowthbookClient = (
    ssrConfig: GrowthBookSSRData,
    tracker: Tracker
): GrowthBook => {
    const {
        env,
        localisation: { country },
    } = useRootData();

    const enableDevMode = env.stage !== 'Production';

    const createGBClient = useLatest(
        () =>
            new GrowthBook({
                apiHost: 'https://cdn.growthbook.io',
                enableDevMode,
                attributes: ssrConfig.attributes,
                features: ssrConfig.features,
                trackingCallback: (experiment, result) => {
                    if (typeof window !== 'undefined') {
                        tracker.log('ExperimentViewed', {
                            url: new URL(window.location.href),
                            targetTracker: ['ga4Tracker', 'archiproTracker'],
                            data: {
                                ExtraData: JSON.stringify({
                                    experiment_id: experiment.key,
                                    variation_id: result.variationId,
                                }),
                            },
                            ga4Data: {
                                event_label: 'experiment_viewed',
                                event_category: 'experiment',
                                experiment_id: experiment.key,
                                variation_id: result.variationId,
                                country,
                            },
                        });
                    }
                },
            })
    );

    const [gb, setClient] = useState(createGBClient.current());

    useEffect(() => {
        if (!enableDevMode) return;
        const listenToGBEvents = (e: MessageEvent) => {
            if (e.data.type === 'GB_SET_OVERRIDES') {
                setClient(createGBClient.current());
            }
        };
        window.addEventListener('message', listenToGBEvents);
        return () => {
            window.removeEventListener('message', listenToGBEvents);
        };
    }, [enableDevMode, createGBClient]);

    return gb;
};

// eslint-disable-next-line import/no-unused-modules
export const useABTest = (): GrowthBook<GrowthBookAppFeatures> =>
    _useGrowthBook<GrowthBookAppFeatures>() as GrowthBook<GrowthBookAppFeatures>;

export const useABTestIsOn = (
    id: keyof GrowthBookAppFeatures,
    professionalID: number | null = null,
    whitelist: number[] = []
): boolean => _whitelistProfessional(id, professionalID, whitelist);

// eslint-disable-next-line unused-imports/no-unused-vars
export const useABTestValue = (id: keyof GrowthBookAppFeatures): string =>
    _useFeatureValue(id, 'control');
